*{
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

/*
--primary-color: #0a1a2b;      
--secondary-color: #1c3b5a;    
--accent-color: #4db8ff;       
--background-color: #10131b;   
--text-color: #e0e8ff;     
*/


:root {
    --primary-color: #233535;
    --secondary-color: #131e15;
    --accent-color: #347575;
    --background-color: #000000;
    --text-color: #e0e8ff;

     
}

/* Light theme */
.light-theme {
    --primary-color: #FFE0B5;
    --secondary-color: #302e2a;
    --accent-color: #e98f28;
    --background-color: #FAF7F0;
    --text-color: #a97a62;
}

.App {
    background-color: var(--background-color);
    color: var(--text-color);
    min-height: 100vh; /* Make sure the background covers the full height of the viewport */
    padding: 20px;
}