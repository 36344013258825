#about{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    margin-right: 20px;
    margin-left: 20px;
    padding-top: 80px;
}

.abt{
    color: var(--text-color);
}


.aboutTitle{
    display: flex;
    align-items: center;
    color: var(--text-color);
    margin-bottom: 0;
}




.aboutTitle::before,
.aboutTitle::after {
  content: "";              /* Necessary for pseudo-elements */
  flex: 1;                  /* Makes the lines take up available space */
  height: 1.5px;  
  width: 30vw;            /* Height of the lines */
  background-color: var(--accent-color);   /* Color of the lines */
  margin: 0 10px;          /* Space between the lines and the title */
}


.aboutFlex {
    display: flex;
    justify-content: space-between; /* Ensures spacing between text and image */
    align-items: center; /* Vertically aligns items in the center */
    padding: 20px;
    gap: 20px; /* Adds spacing between text and image */
}

.abt {
    flex: 8; /* 60% width */
    line-height: 1.6;
    font-size: max(1.5vw, 2vh);
}

.dp {
    flex: 2; /* 40% width */
    width: 40vw;
    height: auto; /* Maintains image aspect ratio */
    border-radius: 10px;
    transition: all 0.5s ease-in-out;
}
.dp:hover{
    transform: translate(-10px, -10px);
    box-shadow: 5px 5px 20px var(--accent-color); /* Adds shadow to bottom and right */
    cursor: pointer;

}

.skill{
    border-radius: 10px;
    transition: all 0.4s ease-in-out;
    filter: blur(0.3px);
    filter: brightness(0.8);
}

.skill:hover{
    cursor: pointer;
    transform: scale(1.08);
    filter: brightness(1);
}

@media(max-width:900px){
    .aboutFlex{
        display: flex;
        flex-direction: column-reverse
    }

    .dp{
        width: 325px;
        height: auto;
    }
    .abt{
        text-align: center;
        font-size: 18px;
    }
}

@media(max-width:550px){
    .skill{
        height: 20px;
    }
}


.center{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
}

.center h2{
    text-align: center;
}

.sendBack{
    z-index: 0;
}